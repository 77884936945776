(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@playkit-js-contrib/common"));
	else if(typeof define === 'function' && define.amd)
		define("ui", ["@playkit-js-contrib/common"], factory);
	else if(typeof exports === 'object')
		exports["ui"] = factory(require("@playkit-js-contrib/common"));
	else
		root["playkit"] = root["playkit"] || {}, root["playkit"]["contrib"] = root["playkit"]["contrib"] || {}, root["playkit"]["contrib"]["ui"] = factory(root["@playkit-js-contrib/common"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2__) {
return 