// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contrib_kitchen-sink-container__root___3bxzN{width:100%;height:100%}.playkit-side-panel-content>div{width:100%;height:100%}\n", "",{"version":3,"sources":["/Applications/XAMPP/xamppfiles/htdocs/playkit-js-contrib/packages/ui/src/components/kitchen-sink-container/_kitchen-sink-container.scss"],"names":[],"mappings":"AAAA,8CACE,UAAW,CACX,WAAY,CACb,gCAKG,UAAW,CACX,WAAY","file":"_kitchen-sink-container.scss","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n}\n\n/* TODO should fix in player and remove */\n:global(.playkit-side-panel-content) {\n  & > div {\n    width: 100%;\n    height: 100%;\n  }\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "contrib_kitchen-sink-container__root___3bxzN"
};
export default ___CSS_LOADER_EXPORT___;
