// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contrib_upper-bar__root___1T--i{display:flex;flex-direction:row}.contrib_upper-bar__root___1T--i .icon--clickable{cursor:pointer}.contrib_upper-bar__root___1T--i>*{margin-left:10px}\n", "",{"version":3,"sources":["/Applications/XAMPP/xamppfiles/htdocs/playkit-js-contrib/packages/ui/src/components/upper-bar/_upper-bar.scss"],"names":[],"mappings":"AAAA,iCAKE,YAAa,CACb,kBAAmB,CANrB,kDAEI,cAAe,CAFnB,mCASI,gBAAiB","file":"_upper-bar.scss","sourcesContent":[".root {\n  :global(.icon--clickable) {\n    cursor: pointer;\n  }\n\n  display: flex;\n  flex-direction: row;\n\n  &>* {\n    margin-left: 10px; // TODO sakal improve\n  }\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "contrib_upper-bar__root___1T--i"
};
export default ___CSS_LOADER_EXPORT___;
