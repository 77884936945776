// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contribpopover-menu__popover-menu___3upQr{padding-top:6px;padding-bottom:6px}\n", "",{"version":3,"sources":["/Applications/XAMPP/xamppfiles/htdocs/playkit-js-contrib/packages/ui/src/components/popover/popover-menu.scss"],"names":[],"mappings":"AAAA,2CACI,eAAgB,CAChB,kBAAmB","file":"popover-menu.scss","sourcesContent":[".popover-menu {\n    padding-top: 6px;\n    padding-bottom: 6px;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover-menu": "contribpopover-menu__popover-menu___3upQr",
	"popoverMenu": "contribpopover-menu__popover-menu___3upQr"
};
export default ___CSS_LOADER_EXPORT___;
