(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("common", [], factory);
	else if(typeof exports === 'object')
		exports["common"] = factory();
	else
		root["playkit"] = root["playkit"] || {}, root["playkit"]["contrib"] = root["playkit"]["contrib"] || {}, root["playkit"]["contrib"]["common"] = factory();
})(window, function() {
return 