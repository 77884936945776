// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contrib_kitchen-sink__root___2dg28{position:absolute;top:0;left:0;overflow:hidden;width:100%;height:100%;transition:opacity 500ms ease-in-out;opacity:0;z-index:0}.contrib_kitchen-sink__root___2dg28.contrib_kitchen-sink__active___2Xv8g{opacity:1;z-index:1}.contrib_kitchen-sink__children___UBMYE{position:absolute;top:0;left:0;overflow:hidden;width:100%;height:100%}.contrib_kitchen-sink__background-layout___2pJ-a{width:100%;height:100%}\n", "",{"version":3,"sources":["/Applications/XAMPP/xamppfiles/htdocs/playkit-js-contrib/packages/ui/src/components/kitchen-sink/_kitchen-sink.scss"],"names":[],"mappings":"AAAA,oCACE,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,eAAgB,CAChB,UAAW,CACX,WAAY,CACZ,oCAAqC,CACrC,SAAU,CACV,SAAU,CATZ,yEAYI,SAAU,CACV,SAAU,CACX,wCAID,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,eAAgB,CAChB,UAAW,CACX,WAAY,CACb,iDAGC,UAAW,CACX,WAAY","file":"_kitchen-sink.scss","sourcesContent":[".root {\n  position: absolute;\n  top: 0;\n  left: 0;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n  transition: opacity 500ms ease-in-out;\n  opacity: 0;\n  z-index: 0;\n\n  &.active {\n    opacity: 1;\n    z-index: 1;\n  }\n}\n\n.children {\n  position: absolute;\n  top: 0;\n  left: 0;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n}\n\n.background-layout {\n  width: 100%;\n  height: 100%;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "contrib_kitchen-sink__root___2dg28",
	"active": "contrib_kitchen-sink__active___2Xv8g",
	"children": "contrib_kitchen-sink__children___UBMYE",
	"background-layout": "contrib_kitchen-sink__background-layout___2pJ-a",
	"backgroundLayout": "contrib_kitchen-sink__background-layout___2pJ-a"
};
export default ___CSS_LOADER_EXPORT___;
