// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contribinjected-component__fill-container___2LHoe{width:100%;height:100%;overflow:hidden}\n", "",{"version":3,"sources":["/Applications/XAMPP/xamppfiles/htdocs/playkit-js-contrib/packages/ui/src/components/injected-component/injected-component.scss"],"names":[],"mappings":"AAAA,mDACE,UAAW,CACX,WAAY,CACZ,eAAgB","file":"injected-component.scss","sourcesContent":[".fill-container {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fill-container": "contribinjected-component__fill-container___2LHoe",
	"fillContainer": "contribinjected-component__fill-container___2LHoe"
};
export default ___CSS_LOADER_EXPORT___;
