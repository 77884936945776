// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contrib_overlay-item-container__overlayItemContainer___1YRFl{position:absolute;z-index:6;top:0;bottom:0;left:0;right:0;width:100%;height:100%}\n", "",{"version":3,"sources":["/Applications/XAMPP/xamppfiles/htdocs/playkit-js-contrib/packages/ui/src/components/overlay-item-container/_overlay-item-container.scss"],"names":[],"mappings":"AAAA,8DACE,iBAAkB,CAClB,SAAU,CACV,KAAM,CACN,QAAS,CACT,MAAO,CACP,OAAQ,CACR,UAAW,CACX,WAAY","file":"_overlay-item-container.scss","sourcesContent":[".overlayItemContainer {\n  position: absolute;\n  z-index: 6;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlayItemContainer": "contrib_overlay-item-container__overlayItemContainer___1YRFl"
};
export default ___CSS_LOADER_EXPORT___;
