// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contrib_toasts-container__toastsContainer___2MBa-{position:absolute;right:0;top:0;padding:8px 16px 0;min-width:120px;max-width:264px;display:flex;flex-direction:column}.contrib_toasts-container__toastRow___2oVhO{height:42px;min-width:120px;max-width:264px;margin-bottom:8px;overflow:hidden;overflow-wrap:break-word;text-overflow:ellipsis;align-self:flex-end}\n", "",{"version":3,"sources":["/Applications/XAMPP/xamppfiles/htdocs/playkit-js-contrib/packages/ui/src/components/toasts-container/_toasts-container.scss"],"names":[],"mappings":"AAAA,mDACE,iBAAkB,CAClB,OAAQ,CACR,KAAM,CACN,kBAAmB,CACnB,eAAgB,CAChB,eAAgB,CAChB,YAAa,CACb,qBAAsB,CACvB,4CAGC,WAAY,CACZ,eAAgB,CAChB,eAAgB,CAChB,iBAAkB,CAClB,eAAgB,CAChB,wBAAyB,CACzB,sBAAuB,CACvB,mBAAoB","file":"_toasts-container.scss","sourcesContent":[".toastsContainer {\n  position: absolute;\n  right: 0;\n  top: 0;\n  padding: 8px 16px 0;\n  min-width: 120px;\n  max-width: 264px;\n  display: flex;\n  flex-direction: column;\n}\n\n.toastRow {\n  height: 42px;\n  min-width: 120px;\n  max-width: 264px;\n  margin-bottom: 8px;\n  overflow: hidden;\n  overflow-wrap: break-word;\n  text-overflow: ellipsis;\n  align-self: flex-end;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastsContainer": "contrib_toasts-container__toastsContainer___2MBa-",
	"toastRow": "contrib_toasts-container__toastRow___2oVhO"
};
export default ___CSS_LOADER_EXPORT___;
