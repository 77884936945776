(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@playkit-js-contrib/common"), require("@playkit-js-contrib/ui"));
	else if(typeof define === 'function' && define.amd)
		define("plugin", ["@playkit-js-contrib/common", "@playkit-js-contrib/ui"], factory);
	else if(typeof exports === 'object')
		exports["plugin"] = factory(require("@playkit-js-contrib/common"), require("@playkit-js-contrib/ui"));
	else
		root["playkit"] = root["playkit"] || {}, root["playkit"]["contrib"] = root["playkit"]["contrib"] || {}, root["playkit"]["contrib"]["plugin"] = factory(root["@playkit-js-contrib/common"], root["@playkit-js-contrib/ui"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__9__) {
return 