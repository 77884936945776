// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contrib_managed-component__fill-container___27fdI{width:100%;height:100%}\n", "",{"version":3,"sources":["/Applications/XAMPP/xamppfiles/htdocs/playkit-js-contrib/packages/ui/src/components/managed-component/_managed-component.scss"],"names":[],"mappings":"AAAA,mDACE,UAAW,CACX,WAAY","file":"_managed-component.scss","sourcesContent":[".fill-container {\n  width: 100%;\n  height: 100%;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fill-container": "contrib_managed-component__fill-container___27fdI",
	"fillContainer": "contrib_managed-component__fill-container___27fdI"
};
export default ___CSS_LOADER_EXPORT___;
